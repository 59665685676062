import React from "react";
import {
  faEnvelope,
  faUserTie,
  faCalendarDays,
  faCheckToSlot,
  faChartSimple,
  faFile,
  faAddressCard,
  faGear,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarWeek,
  faCalendarCheck,
  faTicket,
  faLocationPinLock,
  faLocationDot,
  faLocationPin,
  faSearchLocation,
  faCalendarXmark,
  faPeopleLine,
  faPeoplePulling,
  faUserLarge,
  faUserCheck,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { bookingListTypes } from "../utils/constants";

const AssignZone = React.lazy(() =>
  import("../pages/portConservator/assignZone")
);
const AssignSection = React.lazy(() =>
  import("../pages/portConservator/assignSection")
);

const UpdateSaleQuantity = React.lazy(() =>
  import("../pages/portConservator/updateSaleQuantity")
);
const SpotBookingMaster = React.lazy(() =>
  import("../pages/portConservator/spotBookingMasterData")
);
const SpotBookingSearch = React.lazy(() =>
  import(
    "../pages/portConservator/spotBookingMasterData/components/bookingSearch"
  )
);

const SpotBookingList = React.lazy(() =>
  import(
    "../pages/portConservator/spotBookingMasterData/components/spotBookingList"
  )
);

const AssignSpotZone = React.lazy(() =>
  import(
    "../pages/portConservator/spotBookingMasterData/components/assignSpotZone"
  )
);

const TonLimit = React.lazy(() =>
  import("../pages/portConservator/spotBookingMasterData/components/TonLimit")
);

const Lorry = React.lazy(() => import("../pages/portConservator/lorry"));

const AssignLorry = React.lazy(() =>
  import("../pages/portConservator/lorry/components/assignLorryDashboard")
);
const LorryList = React.lazy(() =>
  import("../pages/portConservator/lorry/components/lorryList")
);
const VechiclePassDetails = React.lazy(() =>
  import("../pages/portConservator/lorry/components/vechiclepassDetails")
);
const LorryDetails = React.lazy(() =>
  import("../pages/portConservator/lorry/components/lorryDetails")
);

const SecondRegistrationView = React.lazy(() =>
  import("../pages/portConservator/secondRegistrationView")
);

const SecondRegistrationRejectionView = React.lazy(() =>
  import("../pages/portConservator/secondRegistrationRejectedList")
);

const ChangeAllottedDate = React.lazy(() =>
  import("../pages/portConservator/changeAllottedDate")
);

const ZoneWiseWorkerDetails = React.lazy(() =>
  import("../pages/portConservator/zoneWiseWorkerDetails")
);

const PCSearchBooking = React.lazy(() =>
  import("../pages/portConservator/searchBooking")
);

const Report = React.lazy(() => import("../pages/portConservator/report"));
const PCSaleReport = React.lazy(() =>
  import("../pages/portConservator/report/components/saleReport")
);
const PCSpotSaleReport = React.lazy(() =>
  import("../pages/portConservator/report/components/spotsaleReport")
);
const MonthlyPermit = React.lazy(() =>
  import("../pages/portConservator/monthlyPermit")
);
const PCSandBooking = React.lazy(() =>
  import("../pages/portConservator/sandBooking")
);
const PCHoliday = React.lazy(() =>
  import("../pages/portConservator/holidaySettings")
);
const PCchangeAllottedDate = React.lazy(() =>
  import("../pages/portConservator/pcChangeAllottedDate")
);
const ZoneStatus = React.lazy(() =>
  import("../pages/portConservator/zoneStatus")
);

const SandPassReprint = React.lazy(() =>
  import("../pages/portConservator/sandPassReprint")
);

const CustomerRegApproval = React.lazy(() =>
  import("../pages/portConservator/customerRegApproval")
);
const SandBooking = React.lazy(() => import("../pages/customer/sandBooking"));
const NormalBookingApproval = React.lazy(() =>
  import("../pages/portConservator/normalBookingApproval")
);
const SpotBookingApproval = React.lazy(() =>
  import("../pages/portConservator/spotBookingApproval")
);
const Zone = React.lazy(() => import("../pages/portConservator/zone"));
const LSGD = React.lazy(() => import("../pages/portConservator/LSGD"));
const LSGDToPort = React.lazy(() =>
  import("../pages/portConservator/LSGDToPort")
);
const Quantity = React.lazy(() => import("../pages/portConservator/quantity"));
const District = React.lazy(() => import("../pages/portConservator/district"));
// const Bank = React.lazy(() => import("../pages/portConservator/bank"));
const Canoe = React.lazy(() => import("../pages/portConservator/canoe"));

const Dashboard = React.lazy(() =>
  import("../pages/portConservator/dashboard")
);

const UserManagement = React.lazy(() =>
  import("../pages/portConservator/userManagement")
);

const CreatePortUser = React.lazy(() =>
  import(
    "../pages/portConservator/userManagement/components/userManagementHome"
  )
);
const ChangeCustomerNo = React.lazy(() =>
  import("../pages/portConservator/userManagement/components/changeCustomerNo")
);
const BlockedUsers = React.lazy(() =>
  import("../pages/portConservator/userManagement/components/blockedUsers")
);
const ViewRegisterdCustomers = React.lazy(() =>
  import("../pages/portConservator/viewRegisteredCustomer")
);
const AdminSaleReport = React.lazy(() =>
  import(
    "../pages/portConservator/consolidatedReports/report/components/saleReport"
  )
);
const AdminSpotSaleReport = React.lazy(() =>
  import(
    "../pages/portConservator/consolidatedReports/report/components/spotsaleReport"
  )
);
const AdminConsolidatedVehiclePassReports = React.lazy(() =>
  import("../pages/portConservator/consolidatedReportVehiclepass/report")
);
const Reports = React.lazy(() =>
  import("../pages/portConservator/consolidatedReports/report")
);

export const pcRoutes = [
  {
    name: "Customer Registration",
    component: <CustomerRegApproval />,
    role: "pc",
    path: "/",
    mainMenu: true,
    // options = {{
    className: "active",
    // }},

    icon: faAddressCard,
  },
  {
    name: "Customer Booking",
    component: <NormalBookingApproval bookingType={bookingListTypes.NORMAL} />,
    role: "pc",
    path: "/customerBookingApproval",
    mainMenu: true,
    icon: faCalendarDays,
  },

  {
    name: "Spot Booking",
    component: <SpotBookingApproval bookingType={bookingListTypes.SPOT} />,
    role: "pc",
    path: "/spotBookingApproval",
    mainMenu: true,
    icon: faCheckToSlot,
  },

  {
    name: "Monthly Permit",
    component: <MonthlyPermit />,
    role: "pc",
    path: "/monthlyPermit",
    mainMenu: true,
    icon: faCalendarPlus,
  },
  // {
  //   name: "Sandbooking",
  //   component: <SandBooking />,
  //   role: "pc",
  //   path: "/sandbooking",
  //   mainMenu: true,
  //   icon: faCalendarWeek,
  // },
  {
    name: "Date Change",
    component: <PCchangeAllottedDate />,
    role: "pc",
    path: "/pcchangeAllottedDate",
    mainMenu: true,
    icon: faCalendarCheck,
  },

  {
    name: "Sand Pass Reprint",
    component: <SandPassReprint />,
    role: "pc",
    path: "/sandPassReprint",
    mainMenu: true,
    icon: faTicket,
  },
  {
    name: " PC Holiday",
    component: <PCHoliday />,
    role: "pc",
    path: "/pcholiday",
    mainMenu: true,
    icon: faCalendarXmark,
  },
  {
    name: "Sand Booking History",
    component: <PCSandBooking />,
    role: "pc",
    path: "/pcsandbookinghistory",
    mainMenu: true,
    icon: faHistory,
  },

  {
    name: " View Registered Customers",
    component: <ViewRegisterdCustomers />,
    role: "pc",
    path: "/registerdCustomers",
    mainMenu: true,
    icon: faUserCheck,
  },

  {
    name: "Zone Status",
    component: <ZoneStatus />,
    role: "pc",
    path: "/zoneStatus",
    mainMenu: true,
    icon: faSearchLocation,
  },
  {
    name: "Reports",
    component: <Report />,
    role: "pc",
    path: "/PCReport",

    icon: faFile,
    subMenuHead: true,
    childrens: [
      {
        name: "PC Sale Report",
        component: <PCSaleReport />,
        role: "pc",
        path: "/PCSaleReport",
      },
      {
        name: "PC Spot Sale Report",
        component: <PCSpotSaleReport />,
        role: "pc",
        path: "/PCSpotSaleReport",
      },
    ],
  },
  {
    name: " Consolidated Reports",
    component: <Reports />,
    role: "pc",
    path: "/PCReport",

    icon: faFile,
    subMenuHead: true,
    childrens: [
      {
        name: " Sale Report",
        component: <AdminSaleReport />,
        role: "pc",
        path: "/pcConolidatedsSaleReport",
      },
      {
        name: " Spot Sale Report",
        component: <AdminSpotSaleReport />,
        role: "pc",
        path: "/pcConsolidatedSpotSaleReport",
      },
    ],
  },
  {
    name: "Consolidated VehiclePass Report",
    component: <AdminConsolidatedVehiclePassReports />,
    role: "pc",
    mainMenu: true,
    path: "/pcconsolidatedVehicleReports",
    icon: faFile,
  },

  {
    name: "Master Settings",
    subMenuHead: true,
    icon: faGear,
    childrens: [
      {
        name: "Add Zone",
        component: <Zone />,
        role: "pc",
        path: "/zone",
      },

      {
        name: "Add New LSGD(Local body)",
        component: <LSGD />,
        role: "pc",
        path: "/LSGD",
      },
      {
        name: "LSGD To Port",
        component: <LSGDToPort />,
        role: "pc",
        path: "/LSGDtoport",
      },
      {
        name: "Add Quantity",
        component: <Quantity />,
        role: "pc",
        path: "/quantity",
      },
      // {
      //   name: "Add District",
      //   component: <District />,
      //   role: "pc",
      //   path: "/district",
      // },
      // {
      //   name: "Add Bank",
      //   component: <Bank />,
      //   role: "pc",
      //   path: "/bank",
      // },
      {
        name: "Add Canoe",
        component: <Canoe />,
        role: "pc",
        path: "/canoe",
      },

      {
        name: "Assign Zone To LSGD(Local body)",
        component: <AssignZone />,
        role: "pc",
        path: "/assignZone",
      },
      {
        name: "Assign Section",
        component: <AssignSection />,
        role: "pc",
        path: "/assignSection",
      },

      {
        name: "User Management",
        component: <UserManagement />,
        role: "pc",
        path: "/userManagement",
        child: true,
        childrens: [
          {
            name: "Create PortUser",
            component: <CreatePortUser />,
            role: "pc",
            path: "/createPortUser",
          },
          {
            name: "Change Customer No",
            component: <ChangeCustomerNo />,
            role: "pc",
            path: "/changeCustomerNo",
          },
          {
            name: "Blocked Users",
            component: <BlockedUsers />,
            role: "pc",
            path: "/blockedUsers",
          },
        ],
      },
      // {
      //   name: "Create PortUser",
      //   component: <CreatePortUser />,
      //   role: "pc",
      //   path: "/createPortUser",
      // },
      {
        name: "Update Sale Quantity",
        component: <UpdateSaleQuantity />,
        role: "pc",
        path: "/updateSaleQuantity",
      },
      {
        name: "Spot Booking",
        component: <SpotBookingMaster />,
        role: "pc",
        path: "/spotBookingMaster",
        child: true,
        childrens: [
          {
            name: "Search Booking",
            component: <SpotBookingSearch />,
            role: "pc",
            path: "/spotBookingSearch",
          },
          {
            name: "List of Online Spot",
            component: <SpotBookingMaster />,
            role: "pc",
            path: "/spotBookingList",
          },
          // {
          //   name: "Spot Registration Assign Zone",
          //   component: <SpotBookingMaster />,
          //   role: "pc",
          //   path: "/assignSpotZone",
          // },
          {
            name: "Add Ton Limit",
            component: <SpotBookingMaster />,
            role: "pc",
            path: "/tonLimit",
          },
        ],
      },
      {
        name: "Lorry",
        component: <Lorry />,
        role: "pc",
        path: "/lorry",

        child: true,
        childrens: [
          {
            name: "Assign Lorry",
            component: <AssignLorry />,
            role: "pc",
            path: "/assignLorry",
          },
          {
            name: "Vehicle Pass Details",
            component: <VechiclePassDetails />,
            role: "pc",
            path: "/vechiclePassDeatailsPc",
          },

          {
            name: "Lorry Details",
            component: <LorryDetails />,
            role: "pc",
            path: "/lorryDetails",
          },
        ],
      },

      {
        name: "Second Registration",
        component: <SecondRegistrationView />,
        role: "pc",
        path: "/secondRegistrationView",
      },
      {
        name: "Second Registration Rejected List",
        component: <SecondRegistrationRejectionView />,
        role: "pc",
        path: "/secondRegistrationRejectView",
      },

      {
        name: "Zone Wise Workers Details",
        component: <ZoneWiseWorkerDetails />,
        role: "pc",
        path: "/zoneWiseWorkerDetails",
      },
      {
        name: "Search Booking",
        component: <PCSearchBooking />,
        role: "pc",
        path: "/PCSearchBooking",
      },
    ],
  },

  {
    name: "Assign Zone",
    component: <AssignZone />,
    role: "pc",
    path: "/assignZone",
  },
  {
    name: "Update Sale Quantity",
    component: <UpdateSaleQuantity />,
    role: "pc",
    path: "/updateSaleQuantity",
  },
  {
    name: "Spot Booking Master",
    component: <SpotBookingMaster />,
    role: "pc",
    path: "/spotBookingMaster",
  },
  {
    name: "Spot Booking Search",
    component: <SpotBookingSearch />,
    role: "pc",
    path: "/spotBookingSearch",
  },
  {
    name: "Spot Booking List",
    component: <SpotBookingList />,
    role: "pc",
    path: "/spotBookingList",
  },
  {
    name: "Assign Spot Zone",
    component: <AssignSpotZone />,
    role: "pc",
    path: "/assignSpotZone",
  },
  {
    name: "Ton Limit",
    component: <TonLimit />,
    role: "pc",
    path: "/tonLimit",
  },
  {
    name: "Lorry",
    component: <Lorry />,
    role: "pc",
    path: "/lorry",
  },
  {
    name: "Assign Lorry",
    component: <AssignLorry />,
    role: "pc",
    path: "/assignLorry",
  },
  {
    name: "Lorry List",
    component: <LorryList />,
    role: "pc",
    path: "/lorryList",
  },
  {
    name: "Vechicle Pass Details",
    component: <VechiclePassDetails />,
    role: "pc",
    path: "/vechiclePassDeatailsPc",
  },
  {
    name: "Lorry Details",
    component: <LorryDetails />,
    role: "pc",
    path: "/lorryDetails",
  },

  {
    name: "Second Registration View",
    component: <SecondRegistrationView />,
    role: "pc",
    path: "/secondRegistrationView",
  },

  {
    name: "secondRegistrationRejectView",
    component: <SecondRegistrationRejectionView />,
    role: "pc",
    path: "/secondRegistrationRejectView",
  },
  // {
  //   name: "changeAllottedDate",
  //   component: <ChangeAllottedDate />,
  //   role: "pc",
  //   path: "/changeAllottedDate",
  // },
  {
    name: "zoneWiseWorkerDetails",
    component: <ZoneWiseWorkerDetails />,
    role: "pc",
    path: "/zoneWiseWorkerDetails",
  },
  {
    name: "PCSearchBooking",
    component: <PCSearchBooking />,
    role: "pc",
    path: "/PCSearchBooking",
  },

  {
    name: "PCSaleReport",
    component: <PCSaleReport />,
    role: "pc",
    path: "/PCSaleReport",
  },
  {
    name: "PCSpotSaleReport",
    component: <PCSpotSaleReport />,
    role: "pc",
    path: "/PCSpotSaleReport",
  },

  // {
  //   name: "monthlyPermit",
  //   component: <MonthlyPermit />,
  //   role: "pc",
  //   path: "/monthlyPermit",
  // },
  // {
  //   name: "sandPassReprint",
  //   component: <SandPassReprint />,
  //   role: "pc",
  //   path: "/sandPassReprint",
  // },
  {
    name: "pcSandbooking",
    component: <PCSandBooking />,
    role: "pc",
    path: "/pcSandbooking",
  },

  {
    name: "PCDashboard",
    component: <Dashboard />,
    role: "pc",
    path: "/PCDashboard",
  },
  // {
  //   name: "pcholiday",
  //   component: <PCHoliday />,
  //   role: "pc",
  //   path: "/pcholiday",
  // },
  // {
  //   name: "zoneStatus",
  //   component: <ZoneStatus />,
  //   role: "pc",
  //   path: "/zoneStatus",
  // },

  {
    name: "changeAllottedDate",
    component: <ChangeAllottedDate />,
    role: "pc",
    path: "/changeAllottedDate",
  },

  {
    name: "zone",
    component: <Zone />,
    role: "pc",
    path: "/zone",
  },

  {
    name: "LSGD",
    component: <LSGD />,
    role: "pc",
    path: "/LSGD",
  },
  {
    name: "LSGDToPort",
    component: <LSGDToPort />,
    role: "pc",
    path: "/LSGDToPort",
  },
  {
    name: "quantity",
    component: <Quantity />,
    role: "pc",
    path: "/quantity",
  },
  {
    name: "district",
    component: <District />,
    role: "pc",
    path: "/district",
  },
  // {
  //   name: "bank",
  //   component: <Bank />,
  //   role: "pc",

  //   path: "/bank",
  // },
  {
    name: "canoe",
    component: <Canoe />,
    role: "pc",
    path: "/canoe",
  },
  {
    name: "userManagement",
    component: <UserManagement />,
    role: "pc",
    path: "/userManagement",
  },
  {
    name: "createPortUser",
    component: <CreatePortUser />,
    role: "pc",
    path: "/createPortUser",
  },
  {
    name: "ChangeCustomerNo",
    component: <ChangeCustomerNo />,
    role: "pc",
    path: "/changeCustomerNo",
  },
  {
    name: "BlockedUsers",
    component: <BlockedUsers />,
    role: "pc",
    path: "/blockedUsers",
  },
  {
    name: "pcchangeAllottedDate",
    component: <PCchangeAllottedDate />,
    role: "pc",
    path: "/pcchangeAllottedDate",
  },
  {
    name: " Sale Report",
    component: <AdminSaleReport />,
    role: "pc",
    path: "/pcConolidatedsSaleReport",
  },
  {
    name: " Spot Sale Report",
    component: <AdminSpotSaleReport />,
    role: "pc",
    path: "/pcConsolidatedSpotSaleReport",
  },

  // {
  //   name: "sandbooking",
  //   component: <SandBooking />,
  //   role: "pc",
  //   path: "/sandbooking",
  // },
  {
    name: "Assign Section",
    component: <AssignSection />,
    role: "pc",
    path: "/assignSection",
  },
  // {
  //   name: "secondRegistrationView",
  //   component: <SecondRegistrationView />,
  //   role: "pc",
  //   path: "/secondRegistrationView",
  // },
  // {
  //   name: "spotBookingMaster",
  //   component: <SpotBookingMaster />,
  //   role: "pc",
  //   path: "/spotBookingMaster",
  // },
  // {
  //   name: "Create PortUser",
  //   component: <CreatePortUser />,
  //   role: "pc",
  //   path: "/createPortUser",
  // },
];
