import { localData } from "./constants";
import { logoutAction } from "../services/modules/auth/authSlice";
import { store } from "../store";

export const HandleLogout = () => {
  localData.remove();
  store.dispatch(logoutAction());
  window.location = "/login";
};
export const getLocalStorageItem = (key, defaultValue = null) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error("Error getting item from local storage:", error);
    return defaultValue;
  }
};